import React from 'react';
import { useModel } from '../core/hooks';
import WidgetNode from './widget-node';

function Fixed({ pageKey }: { pageKey: string }) {
  const fixed = useModel('fixed') || ({} as any);
  if (!pageKey || !fixed) return null;
  const { children = [], visibility = {} } = fixed;

  return (
    <>
      {children
        .filter((id) => !visibility[id] || visibility[id] === true || visibility[id][pageKey])
        .map((id) => (
          <WidgetNode key={id} id={id} fixed />
        ))}
    </>
  );
}

export default React.memo(Fixed);
